export default {
  "transfer-form": "qzN",
  "transfer-form-title": "qzv",
  "transfer-form-subtitle": "qzo",
  "form": "qzn",
  "form-footer": "qzi",
  "footer-btn": "qzA",
  "header": "qzY",
  "main-settings": "qzh",
  "additional-settings": "qzf",
  "form-container": "qzK"
};
