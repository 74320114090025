export default {
  "container": "qgP",
  "item": "qgl caption-bold",
  "hover": "qgX",
  "active": "qgC",
  "name-wrapper": "qgk",
  "name": "qgJ body-2",
  "count": "qgs",
  "actions-placeholder": "qgF",
  "actions": "qgg",
  "show": "qgD",
  "action-button": "qgV"
};
