export default {
  "row": "qLL",
  "col": "qLT",
  "header": "qLz",
  "block": "qLa",
  "col-5": "qLH",
  "col-6": "qLO",
  "col-9": "qLm",
  "col-12": "qLp",
  "hide": "qLx"
};
