export default {
  "wrapper": "qJY",
  "section-wrapper": "qJh",
  "header": "qJf",
  "title": "qJK body-1",
  "close-icon": "qJG",
  "section": "qJr",
  "section-title": "qJb caption-bold",
  "labels": "qJy",
  "labels-title": "qJU",
  "section-checkbox": "qJj",
  "grow": "qJW scroll",
  "label": "qJu",
  "label-spacing": "qsS",
  "buttons": "qsc"
};
