export default {
  "header-cell": "qmN",
  "align-right": "qmv",
  "header-content": "qmo caption-bold",
  "active": "qmn",
  "empty": "qmi",
  "col-12": "qmA",
  "col-8": "qmY",
  "col-6": "qmh",
  "col-4": "qmf",
  "col-2": "qmK",
  "hide": "qmG"
};
