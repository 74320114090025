export default {
  "row": "qau",
  "cell": "qHS",
  "empty": "qHc",
  "icon": "qHq",
  "type": "qHZ",
  "amount-content": "qHR",
  "cell-approve": "qHQ",
  "approve-content": "qHe",
  "row-compact": "qHB"
};
