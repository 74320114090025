export default {
  "wrapper": "qTX",
  "ember-power-select-dropdown": "qTC",
  "status": "qTk body-1",
  "error": "qTJ",
  "success": "qTs",
  "type-container": "qTF",
  "type-close": "qTg",
  "date": "qTD",
  "close-icon": "qTV",
  "container": "qTL",
  "container-picto": "qTT",
  "avatar": "qTz",
  "container-picto-status": "qTa",
  "general": "qTH",
  "general-amount": "qTO",
  "unprocessed": "qTm",
  "fx-rate": "qTp",
  "general-counterparty": "qTx body-2",
  "infobox": "qTw",
  "account-infobox": "qTN qTw",
  "initiator-infobox": "qTv qTw",
  "transaction-infobox": "qTo qTw",
  "repeat-infobox": "qTn qTw",
  "item": "qTi body-2",
  "eye-icon": "qTA",
  "repeat-transfer-action": "qTY",
  "disclaimer": "qTh",
  "start-verification-link": "qTf",
  "financing-not-compliant-disclaimer": "qTK"
};
