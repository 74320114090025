export default {
  "row": "qOZ body-1",
  "active": "qOR",
  "cell": "qOQ",
  "empty": "qOe",
  "ellipsis": "qOB",
  "text-secondary": "qOE",
  "cell-type": "qOd",
  "cell-reason": "qOI",
  "cell-amount": "qOt",
  "row-sidebar": "qOM",
  "animated": "qOP",
  "fadein-item": "qOl",
  "animated-cell": "qOX"
};
