export default {
  "button-wrapper": "qFr",
  "btn-filter": "qFb",
  "name-wrapper": "qFy",
  "preset-name": "qFU",
  "cancel-placeholder": "qFj",
  "cancel-button": "qFW",
  "dropdown-opened": "qFu",
  "dropdown-closed": "qgS",
  "dropdown-menu": "qgc",
  "loading-item": "qgq",
  "placeholder": "qgZ",
  "list-body": "qgR",
  "list-item": "qgQ",
  "error-state": "qge body-2",
  "warning": "qgB",
  "warning-icon": "qgE",
  "footer": "qgd",
  "add-icon": "qgI",
  "empty-state": "qgt"
};
