export default {
  "beneficiary-details": "qzI",
  "beneficiary-country-disclaimer": "qzt",
  "beneficiary-account-types": "qzM",
  "divider": "qzP",
  "error-disclaimer": "qzl",
  "label": "qzX",
  "edition-info-disclaimer": "qzC",
  "sticky-panel": "qzk",
  "spinner": "qzJ"
};
