export default {
  "card-header": "qDX",
  "counterparty-name": "qDC",
  "pan-section": "qDk header-section body-2",
  "infobox": "qDJ header-section",
  "see-card-button": "qDs",
  "card-identifier-section": "qDF",
  "card-icon": "qDg",
  "nickname": "qDD body-1",
  "pan": "qDV body-2"
};
