export default {
  "sidebar-content": "qTR",
  "section": "qTQ",
  "header-wrapper": "qTe",
  "transfer-id": "qTB caption",
  "small-padding": "qTE",
  "title": "qTd",
  "subtitle": "qTI",
  "actions": "qTt"
};
