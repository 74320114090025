export default {
  "row": "qmr",
  "counterparty-name": "qmb",
  "counterparty-status": "qmy",
  "amount": "qmU",
  "next-date": "qmj",
  "end-date": "qmW",
  "frequency": "qmu",
  "active": "qpS",
  "cell": "qpc body-2",
  "no-padding": "qpq",
  "cell-content": "qpZ",
  "counterparty": "qpR",
  "status-avatar": "qpQ",
  "beneficiary-name": "qpe",
  "empty": "qpB",
  "disabled": "qpE",
  "fadein-item": "qpd"
};
