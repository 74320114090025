export default {
  "display-block": "qmS",
  "pay-later-tooltip": "qmc",
  "disabled": "qmq",
  "stepper": "qmZ",
  "installments": "qmR",
  "flex-items": "qmQ",
  "day-label": "qme body-2",
  "installment-amount": "qmB body-1",
  "spacing": "qmE",
  "info-icon": "qmd",
  "detail": "qmI",
  "detail-value": "qmt",
  "extra": "qmM",
  "extra-value": "qmP",
  "badge": "qml",
  "title": "qmX",
  "link": "qmC"
};
