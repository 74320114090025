export default {
  "header-cell": "qVY",
  "header-content": "qVh caption-bold",
  "active": "qVf",
  "align-right": "qVK",
  "empty": "qVG",
  "align-checkbox": "qVr",
  "col-5": "qVb",
  "col-9": "qVy",
  "emitted-at-header-tag": "qVU"
};
