export default {
  "transfer-header": "qVC",
  "infobox": "qVk header-section",
  "proof-section": "qVJ",
  "proof-link": "qVs",
  "download-icon": "qVF",
  "cancel-button": "qVg",
  "request-refund-button": "qVD",
  "refund-requested-indicator": "qVV",
  "recall-indicator": "qVL",
  "payment-purpose": "qVT",
  "payment-purpose-label": "qVz",
  "repeat-infobox": "qVa qVk header-section",
  "additional-infobox": "qVH qVk header-section",
  "repeat-transfer-action": "qVO",
  "financing-not-compliant-disclaimer": "qVm"
};
