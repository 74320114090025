export default {
  "sidebar-header": "qDO",
  "sidebar-header__top": "qDm",
  "sidebar-header__body": "qDp",
  "sidebar-header__content": "qDx",
  "sidebar-header__date": "qDw",
  "infobox": "qDN",
  "infobox--link": "qDv",
  "ghost": "qDo",
  "fail": "qDn",
  "r-transaction-disclaimer": "qDi",
  "hold-disclaimer": "qDA",
  "transaction-link": "qDY body-2",
  "icon-link": "qDh"
};
