export default {
  "vat-lines": "qpy",
  "vat-line": "qpU",
  "vat-input": "qpj",
  "vat-rate": "qpW",
  "vat-option-button": "qpu",
  "disabled": "qxS",
  "vat-option-tooltip": "qxc",
  "remove-label": "qxq",
  "remove-button": "qxZ",
  "vat-total": "qxR",
  "vat-total-amount": "qxQ",
  "add-vat-line": "qxe",
  "with-vat-lines": "qxB"
};
