export default {
  "fileupload": "qpv",
  "file": "qpo",
  "label": "qpn",
  "zone": "qpi",
  "error": "qpA",
  "hide": "qpY",
  "dropzone": "qph",
  "hidden": "qpf",
  "upload-icon": "qpK",
  "dropping": "qpG",
  "bounce2": "qpr",
  "error-message": "qpb"
};
