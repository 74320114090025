export default {
  "transaction": "qFo",
  "transaction-details": "qFn",
  "transaction-details-amount": "qFi body-1",
  "credit": "qFA",
  "negative-amount": "qFY",
  "transaction-details-description": "qFh body-2",
  "transaction-remove-cta": "qFf",
  "avatar": "qFK mr-16",
  "spinner": "qFG"
};
