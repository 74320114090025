export default {
  "row": "qpI",
  "col": "qpt",
  "counterparty": "qpM",
  "header": "qpP",
  "icon": "qpl",
  "col-12": "qpX",
  "col-8": "qpC",
  "col-6": "qpk",
  "col-4": "qpJ",
  "col-2": "qps",
  "hide": "qpF"
};
