export default {
  "custom-export-loading": "qsW",
  "title": "qsu",
  "description": "qFS",
  "section": "qFc",
  "placeholder-avatar": "qFq",
  "_with-avatar": "qFZ",
  "_with-list": "qFR",
  "list-item": "qFQ",
  "radio": "qFe",
  "header": "qFB",
  "header-line": "qFE"
};
