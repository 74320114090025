export default {
  "row": "qVj",
  "item-bulk": "qVW",
  "active": "qVu",
  "disabled": "qLS",
  "cell": "qLc body-2",
  "not-disabled": "qLq",
  "item-amount-disabled": "qLZ",
  "item-amount-credit": "qLR",
  "no-padding": "qLQ",
  "cell-content": "qLe",
  "centered-cell": "qLB",
  "item-counterparty-icon": "qLE mr-16",
  "avatar": "qLd",
  "item-counterparty-name": "qLI",
  "item-counterparty-name-status": "qLt",
  "disputing-chargeback": "qLM",
  "item-counterparty-name-text": "qLP",
  "item-amount": "qLl body-1",
  "item-amount-foreign": "qLX",
  "attachment-icon": "qLC",
  "item-method": "qLk",
  "bank-account-avatar": "qLJ",
  "attachment-status-dot": "qLs",
  "attached": "qLF",
  "missing": "qLg",
  "item-attachment": "qLD",
  "fadein-item": "qLV"
};
