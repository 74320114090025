export default {
  "container": "qgN",
  "header": "qgv",
  "title": "qgo caption-bold",
  "text": "qgn body-2",
  "trigger": "qgi",
  "loading-state": "qgA",
  "dropzone-placeholder-wrapper": "qgY",
  "dropzone-placeholder": "qgh",
  "file-loading": "qgf"
};
