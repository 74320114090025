export default {
  "modal": "qsR",
  "section": "qsQ",
  "header": "qse",
  "title": "qsB",
  "separator-title": "qsE",
  "icon": "qsd",
  "formats": "qsI",
  "columns": "qst",
  "column-list": "qsM",
  "draggable-column": "qsP",
  "column-field": "qsl",
  "column-selector": "qsX",
  "column-with-error": "qsC",
  "handle": "qsk",
  "label-color": "qsJ",
  "disclaimer": "qss"
};
