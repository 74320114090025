export default {
  "header-cell": "qan",
  "empty": "qai",
  "header-type": "qaA",
  "header-requester": "qaY",
  "header-amount": "qah",
  "header-approval": "qaf",
  "header-content": "qaK caption-bold",
  "active": "qaG",
  "row-compact": "qar",
  "row-sidebar": "qab",
  "hidden": "qay",
  "animated": "qaU",
  "fadein-item": "qaj",
  "animated-cell": "qaW"
};
