export default {
  "row": "qHE body-1",
  "active": "qHd",
  "cell": "qHI",
  "empty": "qHt",
  "ellipsis": "qHM",
  "text-secondary": "qHP",
  "transfer-type": "qHl",
  "transfer-type__icon": "qHX",
  "quick-actions": "qHC",
  "amount": "qHk",
  "cell-approve": "qHJ",
  "cell-type": "qHs",
  "cell-requester": "qHF",
  "account-select": "qHg",
  "row-compact": "qHD",
  "row-sidebar": "qHV",
  "cell-amount": "qHL",
  "hidden": "qHT",
  "animated": "qHz",
  "fadein-item": "qHa",
  "animated-cell": "qHH"
};
