export default {
  "filters-checkbox": "qFV mb-8",
  "csv-options-button": "qFL",
  "active": "qFT",
  "options-format-settings-wrapper": "qFz",
  "checkbox": "qFa",
  "options-format-settings": "qFH",
  "hidden": "qFO",
  "visible": "qFm",
  "disabled": "qFp",
  "buttons": "qFx"
};
