export default {
  "modal": "qsF",
  "section": "qsg",
  "header": "qsD",
  "title": "qsV",
  "icon": "qsL",
  "content": "qsT",
  "export-template-wrapper": "qsz",
  "export-template-radio": "qsa",
  "export-template-content": "qsH",
  "export-template-label": "qsO",
  "export-template-button": "qsm",
  "export-template-list": "qsp",
  "inactive": "qsx",
  "export-template-title": "qsw",
  "export-template-description": "qsN",
  "export-template-item-content": "qsv",
  "permission-disclaimer-container": "qso",
  "export-template-footer": "qsn",
  "export-template-cta-container": "qsi",
  "upsell": "qsA",
  "upsell-section": "qsY",
  "_content": "qsh",
  "_cta": "qsf",
  "upsell-title": "qsK",
  "upsell-description": "qsG"
};
