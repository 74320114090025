export default {
  "wrapper": "qLK",
  "title": "qLG",
  "activity-tag": "qLr",
  "select": "qLb",
  "tooltip": "qLy",
  "date-picker": "qLU",
  "internal-notes": "qLj",
  "scheduled": "qLW"
};
